import { BASE_URL } from "../../../constant";
import { ACTIONS, OPEN_POSITION_ENDPOINT } from "./constant";

export function formateObject(name, value) {
  if (name === "name") {
    return { type: ACTIONS.VALIDATE_NAME, payload: { value } };
  }
  if (name === "email") {
    return { type: ACTIONS.VALIDATE_EMAIL, payload: { value } };
  }
  if (name === "phone-number") {
    return { type: ACTIONS.VALIDATE_PHONE, payload: { value } };
  }
  if (name === "current-location") {
    return { type: ACTIONS.CURRENT_LOCATION, payload: { value } };
  }
  if (name === "linkedIn-link") {
    return { type: ACTIONS.LINKED_IN_LINK, payload: { value } };
  }
  if (name === "notice-period") {
    return { type: ACTIONS.NOTICE_PERIOD, payload: { value } };
  }
  if (name === "current-salary") {
    return { type: ACTIONS.CURRENT_SALARY, payload: { value } };
  }
  if (name === "expected-salary") {
    return { type: ACTIONS.EXPECTED_SALARY, payload: { value } };
  }

  return {};
}

/**
 * utility function for handle submit application
 * @param {Function} setApplied
 * @param {Function} setFormSubmit
 * @param {Function} setError
 * @param {Function} setIsServerError
 * @param {String} recaptcha
 * @param {Object} reqBody
 */
export function postData(
  setApplied,
  setFormSubmit,
  setError,
  setIsServerError,
  recaptcha,
  reqBody
) {
  fetch(`${BASE_URL}/website/applications`, {
    method: "POST",
    headers: {
      "True-Client-Ip": "127.0.0.1",
      "X-Correlation-ID": 1,
      "X-Zopsmart-Tenant": "Zopsmart",
      "g-recaptcha-response": recaptcha,
    },
    body: JSON.stringify(reqBody),
  })
    .then((response) => {
      if (response.ok)
        setApplied((prevValues) => {
          return { ...prevValues, status: true };
        });
      else
        setError((prevValues) => {
          return { ...prevValues, status: true };
        });
      setFormSubmit(false);
      if (response.status < 500) return response.json();
      return Promise.reject(response);
    })
    .then((message) => {
      if (message.errors) {
        setError((prevValues) => {
          return {
            ...prevValues,
            status: true,
            message: message.errors[0].reason,
          };
        });
      } else {
        setApplied((prevValues) => {
          return {
            ...prevValues,
            status: true,
            message: message.data?.message,
          };
        });
      }
    })
    .catch((error) => {
      setIsServerError(true);
      setFormSubmit(false);
    });
}

/**
 * utility function for get position data
 * @param {Function} setPositionDescription
 * @param {Function} setPositionDescriptionStatus
 * @param {Number} id
 */
export function getData(
  setPositionDescription,
  setPositionDescriptionStatus,
  id
) {
  fetch(`${BASE_URL}${OPEN_POSITION_ENDPOINT}/${id}`, {
    method: "GET",
    headers: {
      "True-Client-Ip": "127.0.0.1",
      "X-Correlation-ID": 1,
      "X-Zopsmart-Tenant": "Zopsmart",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((position) => {
      setPositionDescription(position);
      setPositionDescriptionStatus(true);
    })
    .catch((error) => {
      setPositionDescriptionStatus(true);
    });
}

import {
  EMAIL_ERROR,
  EMPTY_ERROR,
  PHONE_NUMBER_ERROR,
  EMAIL_REGEX,
  ACTIONS,
  PHONE_REGEX,
  PHONE_REGEX_ERROR,
  LINKEDIN_REGEX,
  LINKEDIN_LINK_ERROR,
} from "./constant";

const validateName = (name) => {
  if (!name.length) return { isValidName: false, errorMsg: EMPTY_ERROR };
  return { isValidName: true };
};

const validateEmail = (email) => {
  if (!email.length) return { isValidEmail: false, errorMsg: EMPTY_ERROR };
  if (!email.match(EMAIL_REGEX))
    return { isValidEmail: false, errorMsg: EMAIL_ERROR };
  return { isValidEmail: true };
};

const validatePhone = (phone) => {
  if (phone.length !== 10)
    return { isValidPhone: false, errorMsg: PHONE_NUMBER_ERROR };
  else if (!phone.match(PHONE_REGEX))
    return { isValidPhone: false, errorMsg: PHONE_REGEX_ERROR };
  return { isValidPhone: true };
};

const validateCurrentLocation = (currentLocation) => {
  if (!currentLocation.length)
    return { isValidCurrentLocation: false, errorMsg: EMPTY_ERROR };
  return { isValidCurrentLocation: true };
};

const validateLink = (link, type) => {
  if (link === "") return { isValidLink: false, errorMsg: EMPTY_ERROR };
  if (!link.match(LINKEDIN_REGEX) && type === "linkedIn")
    return { isValidLink: false, errorMsg: LINKEDIN_LINK_ERROR };
  return { isValidLink: true };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.VALIDATE_NAME: {
      const name = action.payload.value;
      const { isValidName, errorMsg } = validateName(name);
      return {
        ...state,
        name: name,
        invalidFields: {
          ...state.invalidFields,
          name: !isValidName,
          nameErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_EMAIL: {
      const email = action.payload.value;
      const { isValidEmail, errorMsg } = validateEmail(email);
      return {
        ...state,
        email: email,
        invalidFields: {
          ...state.invalidFields,
          email: !isValidEmail,
          emailErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_PHONE: {
      const phone = action.payload.value.slice(0, 10);
      const { isValidPhone, errorMsg } = validatePhone(phone);
      return {
        ...state,
        phone: phone,
        invalidFields: {
          ...state.invalidFields,
          phone: !isValidPhone,
          phoneErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.CURRENT_LOCATION: {
      const currentLocation = action.payload.value;
      const { isValidCurrentLocation, errorMsg } = validateCurrentLocation(
        currentLocation
      );
      return {
        ...state,
        currentLocation,
        invalidFields: {
          ...state.invalidFields,
          currentLocation: !isValidCurrentLocation,
          currentLocationErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.LINKED_IN_LINK: {
      const link = action.payload.value;
      const index = 0;
      const newState = JSON.parse(JSON.stringify(state));
      const { isValidLink, errorMsg } = validateLink(link, "linkedIn");
      newState.links[index].value = link;
      newState.invalidFields.links[index].value.errorMsg = errorMsg;
      newState.invalidFields.links[index].value.valid = !isValidLink;
      return { ...newState };
    }
    case ACTIONS.NOTICE_PERIOD: {
      const noticePeriod = action.payload.value;
      return {
        ...state,
        noticePeriod: noticePeriod,
      };
    }
    case ACTIONS.CURRENT_SALARY: {
      const currentSalary = action.payload.value;
      return {
        ...state,
        currentSalary: currentSalary,
      };
    }
    case ACTIONS.EXPECTED_SALARY: {
      const expectedSalary = action.payload.value;
      return {
        ...state,
        expectedSalary: expectedSalary,
      };
    }
    case ACTIONS.ADDITIONAL_INFO: {
      const additionalInfo = action.payload.value;
      return { ...state, additionalInfo: additionalInfo };
    }
    case ACTIONS.RECAPTCHA: {
      const recaptcha = action.payload.value;
      return {
        ...state,
        recaptcha: recaptcha,
      };
    }
    case ACTIONS.RESUME: {
      const resume = action.payload.value;
      const name = action.payload.name;
      return {
        ...state,
        resume: { name: name, path: resume },
      };
    }
    default:
      return { ...state };
  }
};

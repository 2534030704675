/**
 * base url for all end points
 */
export const BASE_URL =
  typeof window !== "undefined"
    ? window.env?.REACT_APP_HM_BASE_URL || process.env.REACT_APP_HM_BASE_URL
    : process.env.REACT_APP_HM_BASE_URL;
export const CAREER_CAPTCHA_KEY =
  typeof window !== "undefined"
    ? window.env?.REACT_APP_HM_CAPTCHA_KEY ||
      process.env.REACT_APP_HM_CAPTCHA_KEY
    : process.env.REACT_APP_HM_CAPTCHA_KEY;

export const FILE_UPLOAD = '/website/file-upload-url';
import "./editor.css";

import React from "react";

import EditorWithoutCounter from "./editor";
import { Typography } from "@material-ui/core";
import { MAX_CHARACTER_LIMIT } from "../../apply/constant";

export default function Editor(props) {
  const quillEditorLength = props.description.replace(
    /(<([^>]+)>)|\n|&nbsp;/gi,
    ""
  ).length;
  return (
    <div
      className={`counter-editor-${
        quillEditorLength <= MAX_CHARACTER_LIMIT ? "valid" : "invalid"
      }`}
    >
      <EditorWithoutCounter {...props} />
      <div
        className={`word-counter-container-${
          quillEditorLength <= MAX_CHARACTER_LIMIT ? "valid" : "invalid"
        }`}
      >
        <Typography variant="body1">
          {quillEditorLength}/{MAX_CHARACTER_LIMIT}
        </Typography>
      </div>
    </div>
  );
}

import React from "react";
import Link from "gatsby-link";
import { Message } from "../../lib/getMessage";
export default function Thanks({ thanksMessage }) {
  return (
    <div className="error-404">
      <h3>{thanksMessage || <Message dictKey="thank.to.apply" />}</h3>
      <Link to="/careers" className="careers">
        <Message dictKey="click.to.career" />
      </Link>
    </div>
  );
}

import React from "react";
import { Message } from "./../../lib/getMessage";
/**
 * captcha unique key
 */
export const CAPTCHA_SITE_KEY_POSITION =
  "6Le7ReklAAAAAB9iYmtiX96804eLegsZhvNFTDiP";
export const CAPTCHA_SITE_KEY_PUBLIC =
  "6LcG3ckaAAAAALuyrJgq4RWTuKXaMP3sX4Ypyrx6";
export const CAPTCHA_SITE_KEY = "6LfvRb4UAAAAAM6vbv9m4JE9jRcsmngJeF0wbiAo";

/**
 * @description End point for COLLEGES
 */
export const COLLEGES_ENDPOINT = "/colleges";

/**
 * @description End point for COMPANIES
 */
export const COMPANIES_ENDPOINT = "/companies";

/**
 * @description End point for open positions
 */
export const OPEN_POSITION_ENDPOINT = "/website/positions";

/**
 * @description End point for CAPTCHA_TOKEN
 */
export const CAPTCHA_ENDPOINT = "/driveToken";

/**
 * constants for displaying error message
 */
export const EMPTY_ERROR = <Message dictKey="err.must.not.empty" />;
export const EMAIL_ERROR = <Message dictKey="err.must.be.email" />;
export const PHONE_NUMBER_ERROR = <Message dictKey="err.length.must.10" />;
export const PHONE_REGEX_ERROR = <Message dictKey="err.invalid.number" />;
export const SELECT_ERROR = <Message dictKey="err.select.field" />;
export const GITHUB_LINK_ERROR = <Message dictKey="err.link.github.ptofile" />;
export const LINKEDIN_LINK_ERROR = (
  <Message dictKey="err.linkedin.link.format" />
);
export const YEAR_MIN = <Message dictKey="err.greater.than.1900" />;
export const YEAR_MAX = <Message dictKey="err.less.than.220" />;

/**
 * regex for email, links and phone validation for form fields of apply to a position
 */
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX =
  "[1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$";
export const GITHUB_REGEX = /^github.com\/[a-zA-Z0-9]+/;
export const LINKEDIN_REGEX = /^https:\/\/www.linkedin.com\/[a-zA-Z0-9-_]+/;
export const MAX_CHARACTER_LIMIT = 15000;

/**
 * @typedef {Object} InitialStateApplyFormFields
 * @property {String} name
 * @property {String} phone
 * @property {String} email
 * @property {Array} links
 * @property {Object} resume
 * @property {String} additionalInfo
 * @property {String} noticePeriod
 * @property {String} currentSalary
 * @property {String} currentLocation
 * @property {String} expectedSalary
 * @property {string} recaptcha
 * @property {Object} invalidFields
 */

/**
 * @define initial state for form fields of apply to a position
 * @type {InitialStateApplyFormFields} INITIAL_FORM_FIELD_STATE
 */

export const INITIAL_FORM_FIELD_STATE = {
  name: "",
  email: "",
  phone: "",
  resume: { name: "", path: "" },
  links: [{ type: "linkedIn", value: "" }],
  additionalInfo: "",
  noticePeriod: "",
  expectedSalary: "",
  currentSalary: "",
  currentLocation: "",
  recaptcha: "",
  invalidFields: {
    name: false,
    nameErrorMsg: "",
    email: false,
    emailErrorMsg: "",
    phone: false,
    phoneErrorMsg: "",
    links: [
      {
        type: { valid: true, errorMsg: "" },
        typeField: { valid: true, errorMsg: "" },
        value: { valid: false, errorMsg: "" },
      },
    ],
  },
};

/**
 *@define Actions for validating, setting values, add and remove specific section of application form
 */
export const ACTIONS = {
  VALIDATE_NAME: "VALIDATE_NAME",
  VALIDATE_EMAIL: "VALIDATE_EMAIL",
  VALIDATE_PHONE: "VALIDATE_PHONE",
  LINKED_IN_LINK: "LINKED_IN_LINK",
  NOTICE_PERIOD: "NOTICE_PERIOD",
  CURRENT_SALARY: "CURRENT_SALARY",
  EXPECTED_SALARY: "EXPECTED_SALARY",
  ADDITIONAL_INFO: "ADDITIONAL_INFO",
  CURRENT_LOCATION: "CURRENT_LOCATION",
  RESUME: "RESUME",
  RECAPTCHA: "RECAPTCHA",
};

import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import PositionFilter from "./positionFilter.js";
import ImageCarousel from "../../components/ImageSlideShow/AutoImageSlider";
import LayoutWrapper from "../../components/layout";
import { formatDescriptionData } from "../../../utils";
import { BASE_URL } from "../../../constant";
import { SpinnerCircular } from "spinners-react";
import WithProviders from "../../components/WithProviders/index.js";

import Link from "gatsby-link";
import environment from "./images/environment.svg";
import food from "./images/food.svg";
import gadget from "./images/gadget.svg";
import insurance from "./images/insurance.svg";
import Apply from "../../components/apply";
import ThanksMessage from "../../components/apply/thanksMessageOnError";

import teamImg1 from "./images/@MACAWByStories.jpg";
import teamImg2 from "./images/mango_mist.jpg";
import teamImg3 from "./images/@PLaYArena.webp";
import teamImg4 from "./images/@KoramangalaSocial.webp";
import teamImg5 from "./images/gilly_groupie_4.jpg";
import teamImg6 from "./images/AREA83.jpg";
import { Message } from "../../lib/getMessage";
import { PrimaryCustomButton } from "../../components/CustomButton";
import "./style.css";
import { Tooltip } from "@material-ui/core";

/**
 * this contains the list of heading tag
 * @define htmlHeadingTags
 * @type {Array}
 */
const htmlHeadingTags = ["<h1>", "<h2>", "<h3>", "<h4>"];

/**
 * this contains the list of images to be display
 * @define imgData
 * @type {Array}
 */
export const imgData = [
  {
    imgUrl: teamImg1,
    about: {
      place: <Message dictKey="@MACAW.By.Stories" />,
      desc: <Message dictKey="team.lunch" />,
    },
  },
  {
    imgUrl: teamImg2,
    about: {
      place: <Message dictKey="mango.mist" />,
      desc: <Message dictKey="team.outing" />,
    },
  },
  {
    imgUrl: teamImg3,
    about: {
      place: <Message dictKey="@PLaY.Arena" />,
      desc: <Message dictKey="team.outing" />,
    },
  },
  {
    imgUrl: teamImg4,
    about: {
      place: <Message dictKey="@Koramangala.Social" />,
      desc: <Message dictKey="team.lunch" />,
    },
  },
  {
    imgUrl: teamImg5,
    about: {
      place: <Message dictKey="gilly's" />,
      desc: <Message dictKey="team.lunch" />,
    },
  },
  {
    imgUrl: teamImg6,
    about: {
      place: <Message dictKey="area83" />,
      desc: <Message dictKey="team.outing" />,
    },
  },
];

const ImageCard = ({ imgUrl, about }) => (
  <div className="img-card-container">
    <div style={{ backgroundImage: `url(${imgUrl})`, height: "100%" }} />
    {about && (
      <div className="img-info">
        <span>{about.place}</span>
        <span>{about.desc}</span>
      </div>
    )}
  </div>
);

class JobDescription extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the locationElementRef DOM element
    this.locationElementRef = React.createRef();
    this.state = { isOverflowed: false };
  }
  componentDidMount() {
    this.setState({
      isOverflowed:
        this.locationElementRef.current.scrollWidth >
        this.locationElementRef.current.clientWidth,
    });
  }
  render() {
    const { expanded, data, language } = this.props;
    const htmlContent = formatDescriptionData(data?.description);
    let containerStyle =
      !expanded || this.contentRef
        ? {
            maxHeight: expanded ? this.contentRef.offsetHeight + 70 + "px" : 0,
          }
        : {};
    const lang = language ? `/${language}` : "";
    return (
      <div>
        <div
          className={`opening ${expanded ? "expanded" : ""}`.trim()}
          onClick={this.props.onToggle}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div className="position">
            <div className="position__title_and_location">
              <h4 className="position-title">{data?.title}</h4>
              <h4 className="position-openings">
                {data?.currNoOfOpenings}
                {data?.currNoOfOpenings > 1 ? " Openings;" : " Opening;"}
              </h4>
            </div>
            <div className="position__openings_arrow">
              <Tooltip
                title={data?.location?.map((location, index) => [
                  index > 0 && ", ",
                  location.name,
                ])}
                disableHoverListener={!this.state.isOverflowed}
              >
                <h4
                  className="position-vacancy-careers"
                  ref={this.locationElementRef}
                >
                  {data?.location?.map((location) => location.name).join(", ")}
                </h4>
              </Tooltip>
              <div className={`${expanded ? "up-arrow" : "down-arrow"}`.trim()}>
                &nbsp;
              </div>
            </div>
          </div>
          <div className="content-container" style={containerStyle}>
            <div
              className={`description muted`}
              ref={(node) => {
                this.contentRef = node;
              }}
            >
              <div className="qualification">
                {htmlContent?.map((item, key, array) => {
                  if (htmlHeadingTags.find((e) => e === item.substring(0, 4))) {
                    return (
                      <div
                        className="qualification-heading"
                        dangerouslySetInnerHTML={{ __html: item }}
                        key={`qualification-heading-${key}`}
                      />
                    );
                  }
                  if (item.substring(0, 4) === "Note") {
                    return (
                      <div
                        className="apply-note"
                        key={`apply-note-${key}`}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  }
                  if (item.substring(0, 4) === "<li>") {
                    return (
                      <ul
                        className="description-last"
                        key={`qualification-list-element-${key}`}
                      >
                        <div
                          className={
                            array[key + 1] &&
                            array[key + 1].substring(0, 4) !== "<li>"
                              ? "description-last-bullet"
                              : "qualification-list-element"
                          }
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      </ul>
                    );
                  }
                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: item }}
                      key={`description-element-${key}`}
                    />
                  );
                })}
              </div>
              <div className="description-bottom">
                <Link to={`${lang}/careers/${data.slug}`}>
                  <PrimaryCustomButton className="apply-button">
                    <Message dictKey="apply" />
                  </PrimaryCustomButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allPositions: null,
      expandedIndex: null,
      filter: "All Positions",
      status: true,
      careerId: null,
      mailPositionTitle: undefined,
      tokenError: {
        status: props?.location?.state?.tokenError,
        positionId: props.location.state?.positionId,
      },
      filterHeadingMapArray: ["All Positions"],
    };

    this.getOpenings = this.getOpenings.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.clearError = this.clearError.bind(this);
  }

  toggle(index) {
    this.setState((prevState) => {
      let expandedIndex = prevState.expandedIndex === index ? null : index;
      return {
        expandedIndex,
      };
    });
  }

  clearError() {
    this.setState({ tokenError: { status: undefined, positionId: undefined } });
  }

  getOpenings() {
    let _this = this;
    const { language } = this.props;
    let expandedIndex = this.state.expandedIndex;
    let jobOpenings = this.applyFilter();

    let jobs = jobOpenings?.map((job, index) => {
      return (
        <JobDescription
          key={`jobwrap-${index}`}
          onToggle={() => _this.toggle(index)}
          expanded={expandedIndex === index}
          data={job}
          language={language}
        />
      );
    });

    return (
      <div className="job-openings wrap">
        {jobOpenings?.length ? (
          jobs
        ) : (
          <div className="job-openings-empty">
            <div className="no-job-text">
              <Message dictKey="no.jobs" />
            </div>
          </div>
        )}
      </div>
    );
  }

  applyFilter() {
    if (this.state.filter !== "All Positions") {
      return this.state.allPositions.data?.filter(
        (position) => position?.department?.title === this.state?.filter
      );
    }
    return this.state?.allPositions?.data;
  }

  updateFilter(value) {
    return () => {
      this.setState({ filter: value, expandedIndex: undefined });
    };
  }
  getData() {
    return fetch(`${BASE_URL}/website/positions`, {
      method: "GET",
      headers: {
        "True-Client-Ip": "127.0.0.1",
        "X-Correlation-ID": 1,
        "X-Zopsmart-Tenant": "Zopsmart",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((positions) => {
        if (!positions?.data?.message) {
          const headersArray = [];
          this.setState({ allPositions: positions });
          positions.data.forEach((element) => {
            const departmentTitle = element.department.title;
            if (!headersArray.includes(departmentTitle)) {
              headersArray.push(departmentTitle);
            }
          });
          const positionTitle = this.state.allPositions?.data?.filter(
            (position) => position.slug === this.state.tokenError.positionId
          )[0]?.title;
          this.setState({
            mailPositionTitle: positionTitle,
            filterHeadingMapArray: [
              ...this.state.filterHeadingMapArray,
              ...headersArray,
            ],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ status: false });
      });
  }

  componentDidMount() {
    if (window?.innerWidth < 480) {
      this.setState({ mobile: true });
    }
    const params = this.props.location.pathname.split("/");
    const { language } = this.props;
    if (language !== "") {
      this.setState({ careerId: params[3] });
      if (!params[3]) this.getData();
    } else {
      this.setState({ careerId: params[2] });
      if (!params[2]) this.getData();
    }
  }

  render() {
    const { language, updateLanguage, direction, location } = this.props;
    const isDirectionRTL = direction === "rtl";
    return (
      <>
        {this.state.tokenError.status && this.state.mailPositionTitle && (
          <ThanksMessage
            handleClearError={this.clearError}
            message={this.state.mailPositionTitle}
          />
        )}
        {!this.state.careerId ? (
          <LayoutWrapper
            direction={direction}
            language={language}
            location={this.props.location}
          >
            <Helmet title="Find a job | Careers at ZopSmart" />
            <Header
              current="careers"
              language={language}
              updateLanguage={updateLanguage}
              direction={direction}
            />
            <div className="careers">
              <section
                className={`careers-banner ${
                  isDirectionRTL && "careers-banner-rtl"
                }`}
              >
                <div className={`wrap ${isDirectionRTL && "wrap-rtl"}`}>
                  <h1 className="">
                    <Message dictKey="work.with.us" />
                  </h1>
                  <p className="">
                    <Message dictKey="work.with.us.description" />
                  </p>
                </div>
              </section>
              <section className="about-work-with-us wrap">
                <p className="tc">
                  <span className="h1">
                    <Message dictKey="about.working.here" />
                  </span>
                  <Message dictKey="about.working.here.description" />
                </p>
                <hr />
                <div className="careers-intro">
                  <section className={`media ${isDirectionRTL && "media-rtl"}`}>
                    <div
                      className={`media-img ${
                        isDirectionRTL && "media-img-rtl"
                      }`}
                      style={{ backgroundImage: `url(${environment})` }}
                    />
                    <div className="media-details">
                      <p>
                        <span className="h2">
                          <Message dictKey="conducive.environment" />
                        </span>
                        <Message dictKey="conducive.environment.description" />
                      </p>
                    </div>
                  </section>
                  <section className={`media ${isDirectionRTL && "media-rtl"}`}>
                    <div
                      className={`media-img ${
                        isDirectionRTL && "media-img-rtl"
                      }`}
                      style={{ backgroundImage: `url(${gadget})` }}
                    />
                    <div className="media-details">
                      <p>
                        <span className="h2">
                          <Message dictKey="best.gadgets" />
                        </span>
                        <Message dictKey="best.gadgets.description" />
                      </p>
                    </div>
                  </section>
                  <section className={`media ${isDirectionRTL && "media-rtl"}`}>
                    <div
                      className={`media-img ${
                        isDirectionRTL && "media-img-rtl"
                      }`}
                      style={{ backgroundImage: `url(${insurance})` }}
                    />
                    <div className="media-details">
                      <p>
                        <span className="h2">
                          <Message dictKey="medical.insurance" />
                        </span>
                        <Message dictKey="medical.insurance.description" />
                      </p>
                    </div>
                  </section>
                  <section className={`media ${isDirectionRTL && "media-rtl"}`}>
                    <div
                      className={`media-img ${
                        isDirectionRTL && "media-img-rtl"
                      }`}
                      style={{ backgroundImage: `url(${food})` }}
                    />
                    <div className="media-details">
                      <p>
                        <span className="h2">
                          <Message dictKey="snacks.drinks" />
                        </span>
                        <Message dictKey="snacks.drinks.description" />
                      </p>
                    </div>
                  </section>
                </div>
              </section>
              {this.state.mobile ? (
                <ImageCarousel data={imgData} />
              ) : (
                <section className="career-photos wrap">
                  <div className="photo-group">
                    <ImageCard {...imgData[0]} />
                    <ImageCard {...imgData[1]} />
                    <ImageCard {...imgData[2]} />
                  </div>
                  <div className="photo-group">
                    <ImageCard {...imgData[3]} />
                    <ImageCard {...imgData[4]} />
                    <ImageCard {...imgData[5]} />
                  </div>
                </section>
              )}
              <section className="available-positions">
                <p className="tc">
                  <span className="h1">
                    <Message dictKey="join.zopsmart" />
                  </span>
                  <Message dictKey="join.zopsmart.description" />
                </p>
                <hr />
                {this.state.status ? (
                  <div className="loader-wrap">
                    <SpinnerCircular
                      size={90}
                      thickness={100}
                      speed={100}
                      color="#00b6a4"
                      secondaryColor="rgba(0, 0, 0, 0.44)"
                    />
                  </div>
                ) : (
                  <>
                    {this.state.allPositions?.data?.length > 0 && (
                      <PositionFilter
                        current={this.state.filter}
                        allPositions={this.state.filterHeadingMapArray}
                        filterFunc={this.updateFilter}
                        language={language}
                        direction={direction}
                      />
                    )}
                    {this.getOpenings()}
                  </>
                )}
              </section>
            </div>
          </LayoutWrapper>
        ) : (
          <Apply
            id={this.state.careerId}
            language={language}
            location={location}
            updateLanguage={updateLanguage}
            direction={direction}
          />
        )}
      </>
    );
  }
}

export default WithProviders(Careers);

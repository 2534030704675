import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { Message } from "../../lib/getMessage";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ThanksMessage({
  handleClearError,
  message = "ZopSmart Position",
}) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    handleClearError();
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Error!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Message dictKey="thanks.on.error" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Message dictKey="dismiss" />
        </Button>
        <a href={`mailto:hr@zopsmart.com?subject=Application for ${message}`}>
          <Button>
            <Message dictKey="mail.now" />
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
}

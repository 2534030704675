/**
 * this function is to convert markdown to html
 * @function mdToHtml
 ** @param {string} default parameter
 */
export function mdToHtml(str) {
  let tempStr = str;

  // Handle Markdown headers
  if (str.startsWith("-")) {
    tempStr = `<li>${str.substring(2)}</li>`;
  } else if (str.startsWith("####")) {
    tempStr = `<h4>${str.substring(5)}</h4>`;
  } else if (str.startsWith("###")) {
    tempStr = `<h3>${str.substring(4)}</h3>`;
  } else if (str.startsWith("##")) {
    tempStr = `<h2>${str.substring(3)}</h2>`;
  } else if (str.startsWith("#")) {
    tempStr = `<h1>${str.substring(2)}</h1>`;
  }

  // Convert valid Markdown links [text](url)
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  tempStr = tempStr.replace(
    linkRegex,
    (_, text, url) => `<a href="${url}">${text}</a>`
  );

  // Convert incomplete Markdown links [text] to plain text (removing slashes)
  const fallbackLinkRegex = /\[([^\]]+)\]/g;
  tempStr = tempStr.replace(fallbackLinkRegex, (_, text) =>
    text.replace(/\\/g, "")
  );

  // Remove stray backslashes (e.g., '\word' becomes 'word')
  tempStr = tempStr.replace(/\\(?=\w)/g, "");

  // Handle bold (**text**) to <b>
  tempStr = tempStr.replace(/\*\*(.+?)\*\*/g, (_, text) => `<b>${text}</b>`);

  // Handle strong emphasis (__text__) to <strong>
  tempStr = tempStr.replace(
    /__(.+?)__/g,
    (_, text) => `<strong>${text}</strong>`
  );

  // Handle italic (_text_) to <i>
  tempStr = tempStr.replace(/_(.+?)_/g, (_, text) => `<i>${text}</i>`);

  // Remove unmatched bold (**), strong (__), or italics (_) markers
  tempStr = tempStr.replace(/\*\*|__|_/g, "");
  return tempStr;
}

/**
 * this function returns array of string after converting markdown into html
 * @function formatDescriptionDatal
 ** @param {string} default parameter
 */
export function formatDescriptionData(description) {
  const descriptionPoints = description?.split("\n").filter(Boolean); // split description string by new line and filtering non-empty strings
  return descriptionPoints?.map((e) => {
    return mdToHtml(e.trim());
  });
}

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import "../../pages/careers/style.css";
import { Message } from "../../lib/getMessage";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Error({ errorMessage, setError }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setError({ status: false, message: "" });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {errorMessage || <Message dictKey="err.try.again.later" />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Message dictKey="ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { Component } from "react";
import "./style.css";

const timeInterval_MS = 4000;

class AutoImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 1,
    };
    this.sliderFunction = this.sliderFunction.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.changeSlide = this.changeSlide.bind(this);
    this.handleTimerFunction = this.handleTimerFunction.bind(this);
  }

  handleTimerFunction() {
    window.clearInterval(this.setInterval);
    this.setInterval = undefined;
    if (!this.setInterval) {
      let originalImagesData = this.props.data;
      if (originalImagesData && originalImagesData.length > 1) {
        let slide = this.innerContainer;
        let currentImageWidth =
          this.autoImageSlider && this.autoImageSlider.offsetWidth;
        let value = this.state.counter * currentImageWidth;
        if (slide && slide.style) {
          slide.style.transform =
            this.props.dir === "rtl"
              ? `translateX(${value}px)`
              : `translateX(-${value}px)`;
          this.setInterval = window.setInterval(
            () => this.sliderFunction(this.state.imagesArrLength),
            this.props.data.delay || timeInterval_MS
          );
        }
      }
    }
  }

  async changeSlide(index) {
    this.setState({ counter: index });
    this.sliderFunction(this.state.imagesArrLength);
    if (this.setInterval) {
      window.clearInterval(this.setInterval);
      this.setInterval = window.setInterval(() => {
        return this.sliderFunction(this.state.imagesArrLength);
      }, timeInterval_MS);
    }
  }

  handleTouchEnd() {
    let { start, end } = this.state;
    if (start && end) {
      // to avoid nextslide call on single touch
      if (start - end < 0) {
        this.nextSlide("previous");
      } else {
        this.nextSlide("next");
      }
    }
    this.setState({
      start: 0,
      end: 0,
    });
  }

  handleTouchStart(event) {
    this.setState({ start: event.touches[0].clientX });
  }

  handleTouchMove(event) {
    this.setState({ end: event.touches[event.touches.length - 1].clientX });
  }

  async nextSlide(option) {
    if (option === "previous") {
      this.setState({ counter: this.state.counter - 2 });
      this.sliderFunction(this.state.imagesArrLength);
      if (this.setInterval) {
        window.clearInterval(this.setInterval);
        this.setInterval = window.setInterval(() => {
          return this.sliderFunction(this.state.imagesArrLength);
        }, timeInterval_MS);
      }
    } else if (option === "next") {
      this.sliderFunction(this.state.imagesArrLength);
      if (this.setInterval) {
        window.clearInterval(this.setInterval);
        this.setInterval = window.setInterval(() => {
          return this.sliderFunction(this.state.imagesArrLength);
        }, timeInterval_MS);
      }
    }
  }

  async sliderFunction(elements) {
    if (!this.isRunning) {
      this.isRunning = true;
      let slide = this.innerContainer;
      if (slide) {
        let currentImageWidth =
          this.autoImageSlider && this.autoImageSlider.offsetWidth;
        this.setState({ counter: this.state.counter + 1 });
        if (elements > this.state.counter) {
          let value = this.state.counter * currentImageWidth;
          if (slide.style) {
            slide.style.transitionDuration = "0.7s";
            slide.style.transform = `translateX(-${value}px)`;
          }
        }
        if (elements - 1 === this.state.counter) {
          window.setTimeout(() => {
            let currentImageWidth =
              this.autoImageSlider && this.autoImageSlider.offsetWidth;
            let value = 1 * currentImageWidth;
            if (slide.style) {
              slide.style.transitionDuration = "0s";
              slide.style.transform = `translateX(-${value}px)`;
            }
            this.setState({
              counter: 1,
            });
          }, 700);
        }
        if (this.state.counter - 1 < 0) {
          window.setTimeout(() => {
            let currentImageWidth =
              this.autoImageSlider && this.autoImageSlider.offsetWidth;

            let value = (elements - 2) * currentImageWidth;
            if (slide.style) {
              slide.style.transitionDuration = "0s";
              slide.style.transform =
                this.props.dir === "rtl"
                  ? `translateX(${value}px)`
                  : `translateX(-${value}px)`;
            }
            this.setState({
              counter: elements - 2,
            });
          }, 700);
        }
      }
      this.isRunning = false;
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dir !== this.props.dir) {
      this.handleTimerFunction();
    }
  }

  componentDidMount() {
    let originalImagesData = this.props.data;
    if (originalImagesData && originalImagesData.length > 1) {
      this.setState(
        {
          imagesArrLength: originalImagesData.length + 2,
        },
        this.handleTimerFunction
      );
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.setInterval);
  }

  render() {
    let originalImagesData = this.props.data;
    let imagesData = this.props.data;
    if (!imagesData) {
      return null;
    } else {
      if (originalImagesData.length > 1) {
        imagesData = [
          imagesData[imagesData.length - 1],
          ...imagesData,
          imagesData[0],
        ];
      }
      return (
        <div
          ref={(node) => (this.autoImageSlider = node)}
          onMouseEnter={() => window.clearInterval(this.setInterval)}
          onMouseLeave={
            originalImagesData.length > 1 ? this.handleTimerFunction : null
          }
        >
          <div
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            data-testid="auto-img-slider"
            className="slide-show-container"
          >
            <div
              className="inner-container"
              ref={(node) => (this.innerContainer = node)}
            >
              {imagesData.map((item, index) => (
                <div
                  key={index}
                  style={{ backgroundImage: `url(${item.imgUrl})` }}
                  data-testid="image"
                  className="image-container"
                >
                  {item.about && (
                    <div className="img-info">
                      <span>{item.about.place}</span>
                      <span>{item.about.desc}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

AutoImageSlider.defaultProps = {
  config: {
    dots: {
      hideDots: true,
    },
    arrow: {
      hide: true,
    },
  },
};

export default AutoImageSlider;

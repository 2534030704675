import "react-quill/dist/quill.snow.css";
import "./editor.css";

import { marked } from "marked";
import React from "react";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "list",
  "bullet",
  "link",
  "mention",
];

export default function EditorWithoutCounter({
  description,
  onChangeSelection,
  placeholder,
  quillRef,
}) {
  const MarkedDescription = marked.parse(description || "");

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      value={MarkedDescription}
      onChange={onChangeSelection}
      bounds={'[data-text-editor="name"]'}
      placeholder={placeholder}
      ref={quillRef}
    />
  );
}

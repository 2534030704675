import { Tooltip } from "@material-ui/core";
import React from "react";
import { formatDescriptionData } from "../../../utils";
import "../../pages/careers/style.css";

/**
 * this contains the list of heading tag
 * @define htmlHeadingTags
 * @type {Array}
 */
const htmlHeadingTags = ["<h1>", "<h2>", "<h3>", "<h4>"];

/**
 * this component is for displaying particular position which user is applying for in the apply form
 */
export class JobDescription extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the locationElementRef DOM element
    this.locationElementRef = React.createRef();
    this.state = { isOverflowed: false };
  }
  componentDidMount() {
    this.setState({
      isOverflowed:
        this.locationElementRef.current.scrollWidth >
        this.locationElementRef.current.clientWidth,
    });
  }
  render() {
    const { expanded, data } = this.props;
    const htmlContent = formatDescriptionData(data?.description);
    let containerStyle = (!expanded || this.contentRef) && {
      maxHeight: expanded ? this.contentRef.offsetHeight + 70 + "px" : 0,
    };

    return (
      <div className="careers">
        <div
          className={`opening ${expanded ? "expanded" : ""}`.trim()}
          onClick={this.props.onToggle}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <div className="position">
            <div className="position__title_and_location">
              <h4 className="position-title">{data?.title}</h4>
              <h4 className="position-openings">
                {data?.currNoOfOpenings}
                {data?.currNoOfOpenings > 1 ? " Openings;" : " Opening;"}
              </h4>
            </div>
            <div className="position__openings_arrow">
              <Tooltip
                title={data?.location?.map((location, index) => [
                  index > 0 && ", ",
                  location.name,
                ])}
                disableHoverListener={!this.state.isOverflowed}
              >
                <h4 className="position-vacancy" ref={this.locationElementRef}>
                  {data?.location?.map((location) => location.name).join(", ")}
                </h4>
              </Tooltip>
              <div className={`${expanded ? "up-arrow" : "down-arrow"}`.trim()}>
                &nbsp;
              </div>
            </div>
          </div>
          <div className="content-container" style={containerStyle}>
            <div
              className={`description muted`}
              ref={(node) => {
                this.contentRef = node;
              }}
            >
              <div className="qualification">
                {htmlContent?.map((item, key, array) => {
                  if (htmlHeadingTags.find((e) => e === item.substring(0, 4))) {
                    return (
                      <div
                        className="qualification-heading"
                        dangerouslySetInnerHTML={{ __html: item }}
                        key={`qualification-heading-${key}`}
                      />
                    );
                  }
                  if (item.substring(0, 4) === "Note") {
                    return (
                      <div
                        className="apply-note"
                        key={`apply-note-${key}`}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  }
                  if (item.substring(0, 4) === "<li>") {
                    return (
                      <ul
                        className="description-last"
                        key={`qualification-list-element-${key}`}
                      >
                        <div
                          className={
                            array[key + 1] &&
                            array[key + 1].substring(0, 4) !== "<li>"
                              ? "description-last-bullet"
                              : "qualification-list-element"
                          }
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      </ul>
                    );
                  }
                  return (
                    <div
                      dangerouslySetInnerHTML={{ __html: item }}
                      key={`description-element-${key}`}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

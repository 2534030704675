import { fade, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const PrimaryCustomButton = withStyles({
  root: {
    pointerEvents: 'auto !important',
    width: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13.5,
    fontWeight: 600,
    borderRadius: '8px',
    padding: '10px 16px',
    color: '#fff !important',
    lineHeight: '20px',
    backgroundColor: '#52BBAB',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#52BBAB',
      boxShadow: 'none',
    },
    '&:hover:disabled': {
      backgroundColor: fade('#52BBAB', 0.4),
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#52BBAB',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: fade('#52BBAB', 0.4),
      cursor: 'not-allowed',
    },
  },
})(Button)

const SecondaryCustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13.5,
    borderRadius: '8px',
    padding: '10px 16px',
    color: '#1D1E1F',
    lineHeight: '20px',
    backgroundColor: '#EFF0F1',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#EFF0F1',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#EFF0F1',
    },
  },
})(Button)

const DeleteButton = withStyles({
  root: {
    width: 'auto',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 13.5,
    fontWeight: 600,
    borderRadius: '8px',
    padding: '10px 16px',
    color: '#fff',
    lineHeight: '20px',
    backgroundColor: '#FF6C54',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#FF6C54',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#FF6C54',
    },
  },
})(Button)
export { PrimaryCustomButton, SecondaryCustomButton, DeleteButton }

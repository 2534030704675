import React, { useState, useRef } from "react";
import { TextField, Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";

import { PrimaryCustomButton, SecondaryCustomButton } from "../CustomButton";
import { JobDescription } from "./jobDescription";
import { LINKEDIN_REGEX } from "../apply/constant";
import { CAREER_CAPTCHA_KEY } from "../../../constant";

import "./applicationForm.css";
import Editor from "./Editor";

const useStyles = makeStyles(() => ({
  root: {
    width: "50%",
    color: "#52BBAB",
    height: "6.25rem",
    padding: "0 2.15rem",
    border: "1px dashed #B8B8B8",
    marginTop: "5px",
  },
  label: {
    textTransform: "none",
  },
  grid: {
    flexGrow: 1,
  },
}));

export default function ApplicationForm({
  loading,
  positionDescription,
  state,
  handleSubmit,
  handleCancel,
  handleAdditionalInfo,
  handleResume,
  handleCaptcha,
  handleApplicationFormFields,
}) {
  const captchaRef = useRef();
  const classes = useStyles();
  const formRef = useRef();

  const [expandedToggle, setExpandedToggle] = useState(false);

  /**
   * this function is for click on hidden file input(here use for resume upload click)
   */
  const hiddenFileInput = useRef();

  return (
    <div className="complete-form-applicant">
      <div className="main-page-applicant">
        <div className="page-applicant">
          <div
            className="head-applicant"
            onClick={() => {
              setExpandedToggle(!expandedToggle);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            data-testid="testToggle"
          >
            <JobDescription
              expanded={expandedToggle}
              data={positionDescription?.data}
            />
          </div>
          <div className="applicant-container-box">
            <form onSubmit={handleSubmit} className="form" ref={formRef}>
              <div className="applicant-info">
                <section id="applicant-personal-information">
                  <div className={classes.grid}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          Name<span className="asterisk"> *</span>
                        </span>
                        <TextField
                          placeholder="Enter Name"
                          name="name"
                          type="text"
                          id="name"
                          required
                          variant="outlined"
                          inputProps={{
                            "data-testid": "testName",
                            maxLength: 45,
                          }}
                          className="input-applicant"
                          error={state.invalidFields.name}
                          helperText={state.invalidFields.nameErrorMsg}
                          value={state.name}
                          onChange={handleApplicationFormFields}
                          onBlur={handleApplicationFormFields}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          Email<span className="asterisk"> *</span>
                        </span>
                        <TextField
                          placeholder="Enter Email"
                          name="email"
                          className="input-applicant"
                          type="text"
                          id="email"
                          variant="outlined"
                          inputProps={{
                            "data-testid": "testEmail",
                            maxLength: 45,
                          }}
                          required
                          error={state.invalidFields.email}
                          helperText={state.invalidFields.emailErrorMsg}
                          value={state.email}
                          onChange={handleApplicationFormFields}
                          onBlur={handleApplicationFormFields}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          Phone Number
                          <span className="asterisk"> *</span>
                        </span>
                        <TextField
                          placeholder="Enter Phone Number"
                          name="phone-number"
                          type="number"
                          variant="outlined"
                          id="phone-number"
                          className="no-spin input-applicant"
                          inputProps={{
                            "data-testid": "testPhoneNumber",
                          }}
                          required
                          error={state.invalidFields.phone}
                          helperText={state.invalidFields.phoneErrorMsg}
                          value={state.phone}
                          onChange={handleApplicationFormFields}
                          onBlur={handleApplicationFormFields}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          Upload Resume<span className="asterisk"> *</span>
                        </span>
                        <div className="application-same-row-wrapper-item">
                          <TextField
                            inputRef={hiddenFileInput}
                            className="input-applicant-resume"
                            id="resume"
                            type="file"
                            inputProps={{
                              "data-testid": "testResume",
                              accept:
                                "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleResume}
                          />

                          <Button
                            data-testid="testResumeButton"
                            id="add-resume-button"
                            onClick={() => {
                              hiddenFileInput.current.click();
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                            onDragEnter={(e) => {
                              e.preventDefault();
                            }}
                            onDragLeave={(e) => {
                              e.preventDefault();
                            }}
                            onDrop={handleResume}
                            classes={{
                              root: classes.root,
                              label: classes.label,
                            }}
                          >
                            Drag or Upload your resume
                          </Button>

                          {loading.resumeLoading && (
                            <CircularProgress
                              className="resume-name"
                              color="inherit"
                              size={30}
                            />
                          )}
                          <span className="resume-name">
                            {state.resume.name}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          Current Location<span className="asterisk"> *</span>
                        </span>
                        <TextField
                          placeholder="Enter your current location"
                          name="current-location"
                          value={state.currentLocation}
                          required
                          id="current-location"
                          type="text"
                          variant="outlined"
                          className="input-applicant"
                          inputProps={{
                            "data-testid": "testCurrentLocation",
                          }}
                          error={state.invalidFields.currentLocation}
                          helperText={
                            state.invalidFields.currentLocationErrorMsg
                          }
                          onChange={handleApplicationFormFields}
                          onBlur={handleApplicationFormFields}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <span className="inputName-applicant">
                          LinkedIn<span className="asterisk"> *</span>
                        </span>
                        <TextField
                          sx={{ marginTop: "5px" }}
                          placeholder="Enter linkedIn path"
                          type="text"
                          name="linkedIn-link"
                          required
                          value={state.links[0].value}
                          variant="outlined"
                          className="input-applicant"
                          fullWidth
                          id="linkedin-value"
                          error={state.invalidFields.links[0].value.valid}
                          helperText={
                            state.invalidFields.links[0].value.errorMsg
                          }
                          inputProps={{
                            "data-testid": "testLinksText",
                            pattern:
                              (state.invalidFields.links[0].value.valid &&
                                LINKEDIN_REGEX) ||
                              null,
                          }}
                          onChange={handleApplicationFormFields}
                          onBlur={handleApplicationFormFields}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </section>

                <div className={classes.grid}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <span className="inputName-applicant">Notice Period</span>
                      <TextField
                        placeholder="Enter notice period"
                        id="notice-period"
                        name="notice-period"
                        type="text"
                        variant="outlined"
                        value={state.noticePeriod}
                        className="input-applicant"
                        inputProps={{
                          "data-testid": "testNoticePeriod",
                        }}
                        onChange={handleApplicationFormFields}
                        onBlur={handleApplicationFormFields}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="inputName-applicant">
                        Current Salary (In LPA)
                      </span>
                      <TextField
                        placeholder="Enter current salary"
                        type="number"
                        id="current-salary"
                        name="current-salary"
                        variant="outlined"
                        value={state.currentSalary}
                        className="input-applicant"
                        inputProps={{
                          "data-testid": "testCurrentSalary",
                          min: 0,
                        }}
                        onChange={handleApplicationFormFields}
                        onBlur={handleApplicationFormFields}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <span className="inputName-applicant">
                        Expected Salary (In LPA)
                      </span>
                      <TextField
                        placeholder="Enter expected salary"
                        name="expected-salary"
                        value={state.expectedSalary}
                        id="expected-salary"
                        type="number"
                        variant="outlined"
                        className="input-applicant"
                        inputProps={{
                          "data-testid": "testExpectedSalary",
                          min: 0,
                        }}
                        onChange={handleApplicationFormFields}
                        onBlur={handleApplicationFormFields}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <span className="inputName-applicant">
                        Additional Information
                      </span>
                      <Editor
                        wrapperId="additional-info"
                        placeholder="Enter Additional Information"
                        description={state.additionalInfo}
                        onChangeSelection={handleAdditionalInfo}
                      />
                    </Grid>
                    {CAREER_CAPTCHA_KEY && (
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          id="recaptcha"
                          data-testid="testCaptcha"
                          sitekey={CAREER_CAPTCHA_KEY}
                          theme="light"
                          className="captcha-enterprise"
                          onChange={handleCaptcha}
                          ref={captchaRef}
                          onExpired={() => handleCaptcha("")}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
              <div className="button-applicant">
                <div className="button__single">
                  <SecondaryCustomButton
                    onClick={handleCancel}
                    id="cancel-button"
                  >
                    Cancel
                  </SecondaryCustomButton>
                </div>

                <div className="button__single">
                  <PrimaryCustomButton
                    data-testid="testSave"
                    id="submit-button"
                    type="submit"
                    onSubmit={handleSubmit}
                    disabled={
                      !(
                        state.recaptcha &&
                        state.resume.path &&
                        !state.invalidFields.phone
                      )
                    }
                  >
                    Save
                  </PrimaryCustomButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
